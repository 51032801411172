import React from 'react'
import ReactDOM from 'react-dom'
import DagskraBlock from './dagskra-block'
import { store } from '../../store'

$.fn.DagskraBlock = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(DagskraBlock, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-dagskra-block]').DagskraBlock();
});
