import React from 'react'
import ReactDOM from 'react-dom'
import Stock from '../../components/stock'
import { store } from '../../store'

$.fn.Stock = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    options.code = options.stockcode
    ReactDOM.render(React.createElement(Stock, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-stock-info]').Stock();
});
