import $ from 'jquery';
window.$ = $;
window.jQuery = $;
const bootstrap = require('bootstrap');
window.moment = require('moment')

require('./views/livestory')
require('./views/dagskra')
require('./views/company-stock-ticker')
require('./views/currency-table')
require('./views/stock-table')
require('./views/stock')

import "../../sass/main.scss"