import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import queryString from 'query-string'

export const types = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'UPDATE_VALUES', 'CHECKED'], 'CURRENCIES');


export const requestCurrencies = () => {
    return {
        type: types.REQUEST,
    };
};

export const receiveCurrencies = (response, key, query={}) => {
    return {
        type: types.RECEIVE,
        query: query,
        data: response,
        key: key,
    };
};

export const receiveCurrenciesError = () => {
    return {
        type: types.ERROR,
    };
};

export const setCheckedCurrency = (key, currencyKey, checked=false) => {
    return {
        type: types.CHECKED,
        key,
        currencyKey,
        checked
    };
}

export const fetchCurrencies = (key, parameters) => {
    return dispatch => {

        dispatch(requestCurrencies());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch(`https://www.markadurinn.is/api/v1/currencies/?${queryString.stringify(parameters)}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveCurrencies(response, key, parameters));
            } catch (e) {
                dispatch(receiveCurrenciesError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const updateCurrencyValues = (value, currencyCode) => {
    return {
        type: types.UPDATE_VALUES,
        value: value,
        short_code: currencyCode
    }
}