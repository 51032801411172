import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { fetchLiveStory, fetchLiveStoryChildren } from '../../api/livestory/actions'
import LiveStoryItem from '../../components/livestoryitem'


class LiveStoryContainer extends React.Component {
    constructor() {
        super();

    }

    componentDidMount() {
        let _this = this
        const { livestoryId } = this.props
        if(livestoryId) {
            Promise.resolve(this.props.dispatch(fetchLiveStory(livestoryId)))
            .then(() =>{
                this.props.dispatch(fetchLiveStoryChildren(livestoryId))
            })
        }
        setInterval(function() {
            Promise.resolve(_this.props.dispatch(fetchLiveStory(livestoryId)))
            .then(() =>{
                _this.props.dispatch(fetchLiveStoryChildren(livestoryId))
            })
        }, 60*1000);
    }


    render() {
        const { livestory, livestoryChildren } = this.props
        return (
            <div className="livestory-container">
                { livestoryChildren.data.map(item => {

                    return (
                        <LiveStoryItem data={item} />
                    )
                })}
            </div>
        );
    }
}

LiveStoryContainer.defaultProps = {
    livestoryId: null
}

function mapStateToProps(state) {

    var f = {
        livestory: {},
        livestoryChildren: {},
    }
    f.livestory = (state.livestory === undefined) ? {} : state.livestory
    f.livestoryChildren = (state.livestoryChildren === undefined) ? {} : state.livestoryChildren
    return f;
}

export default connect(mapStateToProps)(LiveStoryContainer);


