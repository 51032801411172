import React from 'react';

class CanvasGraph extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    drawData(ctx, data){
        let green = "#009d00";
        let red = "#FF0000";

        //x: 0-300
        //y: 0-100
        let graphHeight = 100;
        let graphWidth = 300;
        let widthDelta = graphWidth/(data.length-1);

        let max = Math.max.apply(null, data.map((el) => el.value))
        let min = Math.min.apply(null, data.map((el) => el.value))
        let heightDelta = (max-min)/graphHeight;

        let x = 0;
        let y = 0;
        let xOld, yOld;

        if (data.length > 0) {

            let refline = (data[0].value - min)/heightDelta;

            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.strokeStyle="#D3D3D3";
            ctx.moveTo(0, graphHeight-refline);
            ctx.lineTo(300, graphHeight-refline);
            ctx.stroke();
            for(var i = 0; i < data.length; i++){
                x = widthDelta * i;
                y = (data[i].value - min)/heightDelta;
                if(i > 0){
                    ctx.beginPath();
                    if(i+1 < data.length){
                        if(yOld < refline && y > refline){
                            let ytotal = y-yOld
                            let delta = (refline - yOld)/ytotal
                            ctx.strokeStyle = red;
                            ctx.moveTo(xOld, graphHeight-yOld);
                            ctx.lineTo(xOld+widthDelta*delta, graphHeight-refline)
                            ctx.stroke();
                            ctx.beginPath();
                            ctx.moveTo(xOld+widthDelta*delta, graphHeight-refline)
                            ctx.strokeStyle = green;
                            ctx.lineTo(x,graphHeight-y);
                            ctx.stroke();
                        }else if(y < refline && yOld > refline){
                                let ytotal = yOld-y
                                let delta = (yOld-refline)/ytotal
                                ctx.strokeStyle = green;
                                ctx.moveTo(xOld, graphHeight-yOld);
                                ctx.lineTo(xOld+widthDelta*delta, graphHeight-refline)
                                ctx.stroke();
                                ctx.beginPath();
                                ctx.moveTo(xOld+widthDelta*delta, graphHeight-refline)
                                ctx.strokeStyle = red;
                                ctx.lineTo(x,graphHeight-y);
                                ctx.stroke();
                        }else{
                            if(y < refline){
                                ctx.strokeStyle = red;
                            }else{
                                ctx.strokeStyle = green;
                            }
                            ctx.moveTo(xOld, graphHeight-yOld);
                            ctx.lineTo(x,graphHeight-y);
                            ctx.stroke();
                        }
                    }
                }
                xOld = x;
                yOld = y;
            }
        }
    }

    componentDidMount(){
        let data = this.props.data
        let c = $(this.refs.myCanvas)[0]
        let ctx = c.getContext("2d");
        
        this.drawData(ctx, data);
    }

    render() {
        return (
            <div className="canvas-container">
                <h4 className="pl-12">30 Dagar</h4>
                <canvas ref="myCanvas" height="100" style={{width: '100%', maxWidth: '150px'}}>
                Your browser does not support the canvas element.
                </canvas>
            </div>
        )
    }
}

CanvasGraph.defaultProps = {
    data: []
}

export default CanvasGraph;