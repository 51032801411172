import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import queryString from 'query-string'

export const types = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'Stockticker');


export const requestStockticker = () => {
    return {
        type: types.REQUEST,
    };
};

export const receiveStockticker = (response, key, query={}) => {
    return {
        type: types.RECEIVE,
        query: query,
        data: response,
        key: key,
    };
};

export const receiveStocktickerError = () => {
    return {
        type: types.ERROR,
    };
};

export const fetchStockticker = (key, slug, parameters={}) => {
    return dispatch => {

        dispatch(requestStockticker());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch(`https://www.markadurinn.is/api/v1/instrument_group/${slug}/?${queryString.stringify(parameters)}`, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveStockticker(response, key, parameters));
            } catch (e) {
                dispatch(receiveStocktickerError(response))
            }


        })
        .catch(handleNetworkError);
    };
};