import React from 'react'
import ReactDOM from 'react-dom'
import StockTable from '../../components/stock-table'
import { store } from '../../store'

$.fn.StockTable = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    options.namespace = "omx";
    options.slug = "omx";
    ReactDOM.render(React.createElement(StockTable, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-stocktable]').StockTable();
});
