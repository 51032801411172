import React from 'react';
import ReactDOM from 'react-dom';

import Fragment from './fragment'

class LiveStoryItem extends React.Component {
    constructor() {
        super();
    }


    render() {
        const { data } = this.props
        return (
            <div className={`livestoryitem ${data.is_key_event ? 'key-item' : ''}`} id={`livestory-item-${data.id}`}>
                <div className="liveblock-header">
                    <div className="time">{data.first_revision_created_at.format('HH:mm')}</div>
                    <h3>{data.title2}</h3>
                </div>
                
            
                { data.rich_body.map((el, index) => {
                    return <div className="liveblock-body"><Fragment data={el} /></div>
                })}
            </div>
        )
    }
}

export default LiveStoryItem;


