import React from 'react';
import {renderFloat} from '../../utils'
import ReactSlider from 'react-slider'

class HighLowGraph extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    buildMarks() {
        const { min, max, current } = this.props;

        let marks = [min, max, current]

        // marks.push(renderFloat(min))
        // marks[max] = renderFloat(max)
        // marks[current] = {
        //     style: {
        //         marginTop: -27
        //     },
        //     label: renderFloat(current)
        // }
        
        return marks
    }

    render() {

        const { min, max, current } = this.props

        return (
            <ReactSlider
            defaultValue={current}
            min={min}
            step={0.001}
            max={max}
            marks={this.buildMarks()}
            renderMark={(props) => <span {...props} />}/>
        )
    }
}

HighLowGraph.defaultProps = {
    data: []
}

export default HighLowGraph;