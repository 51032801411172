import React from 'react'
import ReactDOM from 'react-dom'
import LiveStoryContainer from './livestory'
import LiveStoryLivemomentsContainer from './livestoryLivemoments'
import { store } from '../../store'

$.fn.LiveStory = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(LiveStoryContainer, options), this);
  });
};

$.fn.LiveStoryLivemoments = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(LiveStoryLivemomentsContainer, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-livestory]').LiveStory();
  $('[data-livestory-livemoments]').LiveStoryLivemoments();
});


