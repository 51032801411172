import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { composeWithDevTools } from 'redux-devtools-extension';
// import TokenStorage from './storages/token-storage'
// import { userLoginSuccess } from './api/user/actions'

import { livestory, livestoryChildren } from './api/livestory/reducers'
import { dagskra } from './api/dagskra/reducers'
import { stockTicker } from './components/stockticker/reducers'
import { currencyTable, currencyInputs } from './components/currency-table/reducers'
import { stockTable } from './components/stock-table/reducers'
import { stock} from './components/stock/reducers'

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    livestory,
    livestoryChildren,
    dagskra,
    stockTicker,
    currencyTable,
    currencyInputs,
    stockTable,
    stock
})


// function redirectMiddleware({getState, dispatch}) {
//     return (next) => (action) => {
//         if (typeof action === 'object' && action.hasOwnProperty('type')) {
            
//             if (action.type === AUTH_LOGIN_SUCCESS && action.initiatedByUser) {
//                 if( window.location.pathname === "/") {
//                     next(action);
//                     window.location = garriConfig.storeUrl
//                 }
//             }
//         }

//         return next(action);
//     };
// }

let middleware = null
// if(process.env.NODE_ENV === 'production') {
//     middleware = applyMiddleware(
//         thunkMiddleware,
        
//     )
// } else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
// }

export const store = createStore(reducers, middleware)


// const token = TokenStorage.getItem('token');
// if (token !== null) {
//     store.dispatch(userLoginSuccess(token));
// }