import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import { fetchLiveStory, fetchLiveStoryChildren } from '../../api/livestory/actions'
import LiveStoryItem from '../../components/livestoryitem'


class LiveStoryLiveMomentsContainer extends React.Component {
    constructor() {
        super();

    }

    render() {
        const { livestory, livestoryChildren } = this.props
        return (
            <div className="livestory-livemoments-container">
                { livestoryChildren.data.filter((el) => el.is_key_event).map(item =>
                    <a href={`#livestory-item-${item.id}`} className="livemoment-element">
                        <div className="livemoment-container">
                            <div className="time">{item.first_revision_created_at.format('HH:mm')}</div>
                            <div className="title">{item.title}</div>
                        </div>
                        
                    </a>
                )}
            </div>
        );
    }
}

LiveStoryLiveMomentsContainer.defaultProps = {
    livestoryId: null
}

function mapStateToProps(state) {

    var f = {
        livestory: {},
        livestoryChildren: {},
    }
    f.livestory = (state.livestory === undefined) ? {} : state.livestory
    f.livestoryChildren = (state.livestoryChildren === undefined) ? {} : state.livestoryChildren
    return f;
}

export default connect(mapStateToProps)(LiveStoryLiveMomentsContainer);


