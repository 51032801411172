import React from 'react';
import {fetchCurrencies, setCheckedCurrency, updateCurrencyValues } from './actions'
import { connect } from 'react-redux'
import CurrencyInput from './currencyInput';


class CurrencyTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            data: [],
        }
    }

    fetchUpdate(prevProps, prevState) {
        const { currencies, tags, date, namespace, showActiveCurrencies } = this.props
        let params = {
            currencies,
            tags,
            date,
            showActiveCurrencies,
        }
        return this.props.dispatch(fetchCurrencies(namespace, params))
    }

    componentDidMount(prevProps, prevState) {
        Promise.resolve(this.fetchUpdate()).then(() => {
            this.props.dispatch(updateCurrencyValues(1000, "ISK"))
        })
        var interValTime = 5 * 60 * 1000
        setInterval(() => {
            this.fetchUpdate()
        }, interValTime)
    }

    checkChange(curr, e){
        const { namespace } = this.props
        this.props.dispatch(setCheckedCurrency(namespace, curr, e.target.checked))
    }


    render() {
        try {
            let { data } = this.props.currencyTable[this.props.namespace]
            const currencies = data.map((curr) =>
                <tr key={curr.currency}>
                    <td className="flag">{curr.currency}</td>
                    <td className="text-right">{curr.landsbankikaupgengi}</td>
                    <td className="text-right">{curr.landsbankisolugengi}</td>
                    <td className="text-right input"><CurrencyInput currencyCode={curr.currency} store={this.props.store} /></td>
                </tr>
            );
            return (<table className="currency-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th className="text-right">Kaup</th>
                            <th className="text-right">Sala</th>
                            <th className="text-right">Myntbreytir</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currencies}
                        </tbody>
                    </table>)
        }
        catch(e) {
            return <div />
        }
    }
}

CurrencyTable.defaultProps = {
    date: "today",
    currencies: [],
    tags: [],
    namespace: "landsbanki",
    columnNames: [],
}


function mapStateToProps(state) {

    var f = {
        currencyTable: {},
    }
    f.currencyTable = (state.currencyTable === undefined) ? {} : state.currencyTable
    return f;
}

export default connect(mapStateToProps)(CurrencyTable);
