import React from 'react';
import {fetchStockticker} from './actions'
import CanvasGraph from '../canvas-graph'
import { connect } from 'react-redux'
import { renderPercentage } from '../../utils'

class StockTicker extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }
    

    fetchTicker(prevProps, prevState) {
        const { slug, namespace } = this.props
        this.props.dispatch(fetchStockticker(namespace, slug, {include_history: true}))
    }

    componentDidMount(prevProps, prevState) {
        this.fetchTicker()
        var interValTime = 5 * 60 * 1000
        setInterval(() => {
            this.fetchTicker()
        }, interValTime)
    }


    render() {
        try {
            let { data, isFetching } = this.props.stockTicker[this.props.namespace]
            return (
                <div className="container-fluid stockticker">
                    <div className="row">
                        {data.map(function(stock, index){
                            return (
                                <div className="col-md-2 col-sm-4 col-6 ticker-col" key={ index }>
                                    <div className="stock-title pl-12"><a href={`/markadurinn/stocks/${stock.stock}`}>{stock.stock}</a></div>
                                    <CanvasGraph
                                        data={stock.historical_data}
                                    />
                                    <div className="stock-value pl-12">{(stock.lastprice || 0).toFixed(2)} ({renderPercentage(stock.change || 0)})</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
        catch(e) {
            return <div />
        }
    }
}

StockTicker.defaultProps = {
    slug: null,
    namespace: "stockticker",
}


function mapStateToProps(state) {

    var f = {
        stockTicker: {},
    }
    f.stockTicker = (state.stockTicker === undefined) ? {} : state.stockTicker
    return f;
}

export default connect(mapStateToProps)(StockTicker);
