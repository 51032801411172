import React from 'react';
import { connect } from 'react-redux';

export const Spinner = (props) => {
  /* props.size can be
     lg
     2x
     3x
     4x
     5x
  */
  let size = ''
  if (props.size) {
    size =`fa-${props.size}` 
  }
  return <i className={`spinner fa fa-spinner fa-pulse ${size}`}></i>
};

export const parseJSON = response => {
    return response.json();
}

export const handleNetworkError = (error) => {
    if (window.Raven !== undefined) {
      Raven.captureException(error)
    } else {
      throw (error)
    }
  }

export const renderFloat = (value) => {
    if (value === null || value === undefined) {
      value = 0
    }
    return Number.parseFloat(value).toFixed(2)
  }

export const renderPercentage = (value) => {
    if (value === null || value === undefined) {
      value = 0
    }
    let color = "";
    if (value < 0) {
      color = "percentage-red";
    }
    if (value > 0) {
      color = "percentage-green";
    }
    let formatted_value = `${renderFloat(value)}%`
    return <span className={color}>{formatted_value}</span>
  }


  export const renderFinancialShort = (value) => {
    const sufixes = ['', 'þ', 'm', 'ma'];
    const i = Math.floor(Math.log(value) / Math.log(1000));
    return !value && '0' || (value / Math.pow(1000, i)).toFixed(0) + "" + sufixes[i];
  }
  
  export const renderNumber = (value) => {
    if (value === null || value === undefined) {
      return "-"
    }
    return value
  }

  export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }
  


  export const formatISK = (d, decimals=0) => {
    return toMoney(d, decimals, ',', '.');
  }