import React from 'react';
import {fetchStock} from './actions'
import { connect } from 'react-redux'
import { renderFinancialShort, renderPercentage } from '../../utils';


class StockTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            columns: [],
            data: [],
        }
    }

    fetchStockTable(prevProps, prevState){
        const { slug, namespace, path } = this.props
        this.props.dispatch(fetchStock(namespace, slug, path))
    }

    componentDidMount(prevProps, prevState) {
        this.fetchStockTable()
        var interValTime = 5 * 60 * 1000
        setInterval(() => {
            this.fetchStockTable()
        }, interValTime)
    }


    render() {
        try {
            let { data, columns, isFetching, grouping } = this.props.stockTable[this.props.namespace]
            const stocks = data.map((stock) =>
                <tr key={stock.key}>
                    <td><a href={`stocks/${stock.key}`}>{stock.key}</a></td>
                    <td className="text-right">{stock.lastprice == null ? '-' : stock.lastprice.toFixed(2)}</td>
                    <td className="text-right">{renderPercentage(stock.change)}</td>
                    <td className="text-right">{stock.bidprice == null ? '-' : stock.bidprice.toFixed(2)}</td>
                    <td className="text-right">{stock.askprice == null ? '-' : stock.askprice.toFixed(2)}</td>
                    <td className="text-right">{renderFinancialShort(stock.turnover)}</td>
                </tr>
            );
            return (<table className=" stock-table w-100">
                <thead>
                <tr>
                    <th>Auðkenni</th>
                    <th className="text-right">Verð</th>
                    <th className="text-right">Breyting</th>
                    <th className="text-right">Kaup</th>
                    <th className="text-right">Sala</th>
                    <th className="text-right">Magn</th>
                </tr>
                </thead>
                <tbody>
                {stocks}
                </tbody>
            </table>)
        }
        catch(e) {
            return <div />
        }
    }
}

StockTable.defaultProps = {
    slug: null,
    namespace: "stocktable",
    path: "/stock",
    classes: "",
}


function mapStateToProps(state) {

    var f = {
        stockTable: {},
    }
    f.stockTable = (state.stockTable === undefined) ? {} : state.stockTable
    return f;
}

export default connect(mapStateToProps)(StockTable);
