import React from 'react';
import {updateCurrencyValues} from './actions'
import { connect } from 'react-redux'
import connectWithStore from '../../utils/store'
import { store } from '../../store'

class CurrencyInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    onChange(e){
        var value = e.target.value
        var reg = /^\d+$/;
        if(reg.test(value)){
            this.props.dispatch(updateCurrencyValues(value, this.props.currencyCode))
        }
    }

    render() {
        var value = 0
        if(this.props.currencyInputs[this.props.currencyCode]){
            value = this.props.currencyInputs[this.props.currencyCode].value
        }

        return (
            <input
                className="text-right"
                value={value}
                onChange={this.onChange.bind(this)}
                onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
            />
        )
    }
}

CurrencyInput.defaultProps = {
}

function mapStateToProps(state) {

    var f = {
        currencyInputs: {}
    }
    f.currencyInputs = (state.currencyInputs === undefined) ? {} : state.currencyInputs
    return f;
}

export default connect(mapStateToProps)(CurrencyInput);
