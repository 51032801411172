import React from 'react';
import moment from 'moment'

const calculateWidth = (item) => {
  const { starts, ends } = item;
  return (new Date(ends) - new Date(starts)) / (1000 * 3600 / 2)
}

const calulatePosition = ({ item, items, itemWidth }) => {
  const firstStarts = moment(items[0].starts)
  const itemStarts = moment(item.starts)
  const diff = moment.duration(itemStarts.diff(firstStarts)).asMinutes() / 30;
  return diff * itemWidth;
}

class Dagskrarlidur extends React.Component {
  render() {
    const { item, itemWidth, items } = this.props
    let position = calulatePosition({ item, items, itemWidth })
    const styles = {
      width: `${calculateWidth(item) * itemWidth}px`,
      left: `${position}px`
    }
    return (
      <div key={item.id} className="slide-box" style={styles}>
        <a href={item.url || 'javascript:void(0);'} target={item.url ? '_blank':''}>
          <div className="slide-box-inner">
            <div className="title">{item.title}</div>
            <div className="time-range">{item.time_range}</div>
            <div className="play-icon"><svg width="16" height="16" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M79 39.5C79 61.3152 61.3152 79 39.5 79C17.6848 79 0 61.3152 0 39.5C0 17.6848 17.6848 0 39.5 0C61.3152 0 79 17.6848 79 39.5ZM27 61V21L59 39.5L27 61Z" fill="white" />
            </svg>
            </div>
          </div>
        </a>
      </div>
    )
  }
}

export default Dagskrarlidur;


