import React from 'react';

import { livestoryActionTypes, livestoryChildrenActionTypes } from './actions'
import moment from 'moment'


export function livestory(state = {
    lastReceived: null,
    isFetching: false,
    data: {},
}, action) {
    
    switch (action.type) {
        
        case livestoryActionTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case livestoryActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            if (action.data.items.length > 0) {
                state.data = action.data.items[0]
            }
            state.lastReceived = moment()

            return state

        default:
            return state
    }
}

export function livestoryChildren(state = {
    lastReceived: null,
    isFetching: false,
    data: [],
}, action) {
    
    switch (action.type) {
        
        case livestoryChildrenActionTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case livestoryChildrenActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data.items.map(item => {
                let i = {...item}
                i.first_revision_created_at = moment(item.first_revision_created_at)
                return i
            })
            
            state.lastReceived = moment()

            return state

        default:
            return state
    }
}