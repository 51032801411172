import React from 'react';
import moment from 'moment';

class DagskraDate extends React.Component {
  render() {
    const { date, active, onClick, title } = this.props
    return (
      <div
        key={date}
        className={`date-element ${active ? 'active' : ''}`}
        onClick={onClick.bind(this, date)}
      >{(title !== undefined) ?
        <b>{ title } </b>
        : <><b>{ moment(date).locale('is').format('ddd')}</b> {moment(date).locale('is').format('DD.MMM')}</>}
      </div>
    )
  }
}

export default DagskraDate;


