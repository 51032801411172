import React from 'react';
import { connect } from 'react-redux'
import PerfectScrollbar from 'perfect-scrollbar';
import { fetchDagskra, setDagskraDate } from '../../api/dagskra/actions';
import DagskrarLidur from './dagskrarlidur';
import DagskraDate from './dagskra-date'
import DagskraTimeLine from './dagskra-timeline'
import moment from 'moment';

class DagskraBlock extends React.Component {
  constructor() {
    super();
    this.sliderRef = React.createRef();
    this.datesRef = React.createRef();
  }

  componentDidMount() {
    const { starts, ends, dispatch, blockKey } = this.props;
    dispatch(fetchDagskra({ starts, ends }, blockKey)).then(() => {
      const { sliderRef, datesRef } = this;
      new PerfectScrollbar(sliderRef.current, {
        swipeEasing: true,
        minScrollbarLength: 50,
        maxScrollbarLength: 50,
      });
      new PerfectScrollbar(datesRef.current, {
        suppressScrollY: true,
        swipeEasing: true,
        minScrollbarLength: 50,
        maxScrollbarLength: 50,
      });
    })
  }

  setActiveDate(date) {
    const { dispatch, blockKey } = this.props;
    dispatch(setDagskraDate(date, blockKey))
  }

  render() {
    const { dagskra, itemWidth, blockKey } = this.props;
    const data = dagskra[blockKey];
    if (!data) {
      return <div/>;
    }
    const today = moment().format('YYYY-MM-DD');
    return (
      <div className="dagskra-block-inner-container">
        <div className="today-dagskra">
          <h2>Dagskráin</h2>
          { (data.currentDate && today === data.currentDate) &&
            <DagskraDate
              key={`${data.currentDate}-nuna`}
              date={data.currentDate}
              active={true}
              onClick={this.setActiveDate.bind(this)}
              title={'Núna'}
            />
          }
        </div>
        <div ref={this.datesRef} className="dates-container">
          <div className="dates-inner-container">
            {data.dates.map((date) =>
              <DagskraDate
                key={date}
                date={date}
                active={date === data.currentDate}
                onClick={this.setActiveDate.bind(this)}
              />
            )}
          </div>
        </div>
        <div ref={this.sliderRef} className="slider-container-mobile">
          <div className="col-md-12 col-2">
            <div className="slider-container">
              {data.currentDate &&
                <DagskraTimeLine
                  itemWidth={itemWidth}
                  totalWidth={(data.dagskraTimeRange) * itemWidth}
                  items={data.items[data.currentDate]}
                />
              }
            </div>
          </div>
          <div className="col-md-12 col-10 slider-container-col">
            <div className="slider-inner">
              {data.currentDate && data.items[data.currentDate].map((item) =>
                <DagskrarLidur
                  key={item.time_range}
                  item={item}
                  items={data.items[data.currentDate]}
                  itemWidth={itemWidth}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DagskraBlock.defaultProps = {
  starts: '',
  ends: '',
  dagskra: {},
  itemWidth: 300,
  blockKey: 'el',
}

function mapStateToProps(state) {
  var f = {
    dagskra: {},
  }
  f.dagskra = (state.dagskra === undefined) ? {} : state.dagskra
  return f;
}

export default connect(mapStateToProps)(DagskraBlock);
