import { dagskraActionTypes } from './actions'
import moment from 'moment'

const initalState = {
  isFetching: false,
  items: {},
  dates: [],
  dateUnits: {},
  currentDate: undefined,
  dagskraTimeRange: undefined,
};
export function dagskra(state={}, action) {
  const { key } = action;
  switch (action.type) {
    case dagskraActionTypes.REQUEST:
      state = { ...state }
      if (!state[key]) {
        state[key] = { ...initalState }
      }
      state[key].isFetching = true
      return state

    case dagskraActionTypes.RECEIVE:
      state = { ...state }
      state[key].isFetching = false
      state[key].items = action.data.items;
      state[key].dates = action.data.dates
      state[key].dateUnits = action.data.date_units
      state[key].currentDate = action.data.dates.find((date) => date === moment().format('YYYY-MM-DD'))
      if (!state[key].currentDate) {
        state[key].currentDate = action.data.dates[0]
      }
      state[key].dagskraTimeRange = state[key].dateUnits[state[key].currentDate]
      return state

    case dagskraActionTypes.SET_DATE:
      state = { ...state };
      if (state[key].items[action.date]) {
        state[key].currentDate = action.date
        state[key].dagskraTimeRange = state[key].dateUnits[state[key].currentDate]
      }
      return state

    default:
      return state
  }
}
