import React from 'react';
import ReactDOM from 'react-dom';

class Fragment extends React.Component {
    constructor() {
        super();
        this.htmlcontent = React.createRef();
    }

    componentDidMount() {
        $(ReactDOM.findDOMNode(this.htmlcontent.current)).html(this.props.data.rendered);
    }

    componentDidUpdate() {
        $(ReactDOM.findDOMNode(this.htmlcontent.current)).html(this.props.data.rendered);
    }


    render() {
        return (
            <div ref={this.htmlcontent} />
        )
    }
}

export default Fragment;


