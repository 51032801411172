import React from 'react'
import ReactDOM from 'react-dom'
import StockTicker from '../../components/stockticker/stockticker'
import { store } from '../../store'

$.fn.StockTicker = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    options.namespace = "ticker";
    options.slug = "ticker";
    ReactDOM.render(React.createElement(StockTicker, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-stockticker]').StockTicker();
});
