import React from 'react';
import moment from 'moment'

class DagskraTimeline extends React.Component {
  render() {
    const { totalWidth, items, itemWidth } = this.props
    let starts = moment(items[0].starts);
    const ends = moment(items[items.length - 1].ends);
    const times = [];
    while (starts < ends) {
      if (starts.format("mm") === '00') {
        const timePush = {
          time: starts.format("HH:mm"),
        };
        if (starts.add(30, 'minutes') > moment(ends).subtract(60, 'minutes')) {
          timePush.time2 = starts.format("HH:mm")
        }
        times.push(timePush)
        if (timePush.time2) {
          break;
        }
      }
      starts = starts.add(30, 'minutes')
    }
    return (
      <div className="timeline-container">
        <div className="timeline-inner" style={{width: times.length * 2 * itemWidth}}>
          {times.map((time) =>
            <div className="time-unit" style={{width: `${itemWidth * 2}px`}} key={time.time}>
              <span>{time.time}</span>
                <div className="t-container">
                <div className="t1"></div>
                <div className="t2"></div>
                <div className="t3">{time.time2 && <span>{time.time2}</span>}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default DagskraTimeline;


