import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import queryString from 'query-string'

export const types = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'StockTimeSeries');


export const requestStockTimeSeries = () => {
    return {
        type: types.REQUEST,
    };
};

export const receiveStockTimeSeries = (response, key, query = {}) => {
    return {
        type: types.RECEIVE,
        query: query,
        data: response,
        key: key,
    };
};

export const receiveStockTimeSeriesError = () => {
    return {
        type: types.ERROR,
    };
};

export const fetchStockTimeSeries = (key, parameters) => {
    return dispatch => {

        dispatch(requestStockTimeSeries());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let urlParams = ''
        if (parameters['full_history'] !== undefined) {
            urlParams = `?full_history=true` 
        }


        return fetch(`https://www.markadurinn.is/api/v1/instrument/${(parameters['short_code'])}/${urlParams}`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {

                try {
                    dispatch(receiveStockTimeSeries(response, key, parameters));
                } catch (e) {
                    dispatch(receiveStockTimeSeriesError(response))
                }


            })
            .catch(handleNetworkError);
    };
};