import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const livestoryActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'SELECT'], 'LIVESTORY');
export const livestoryChildrenActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'SELECT'], 'LIVESTORY_CHILDREN');


export const requestLiveStory = () => {
    return {
        type: livestoryActionTypes.REQUEST,
    };
};

export const receiveLiveStory = (response) => {
    return {
        type: livestoryActionTypes.RECEIVE,
        data: response,
    };
};

export const receiveLiveStoryError = () => {
    return {
        type: livestoryActionTypes.ERROR,
    };
};

export const requestLiveStoryChildren = () => {
    return {
        type: livestoryChildrenActionTypes.REQUEST,
    };
};

export const receiveLiveStoryChildren = (response) => {
    return {
        type: livestoryChildrenActionTypes.RECEIVE,
        data: response,
    };
};

export const receiveLiveStoryChildrenError = () => {
    return {
        type: livestoryChildrenActionTypes.ERROR,
    };
};


export const fetchLiveStory = (id) => {
    return dispatch => {

        dispatch(requestLiveStory());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v2/pages/?id=${id}&type=frettabladid_cms.LiveStoryPage&fields=id,latest_revision_timestamp`


        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveLiveStory(response));
            } catch (e) {
                dispatch(receiveLiveStoryError(response))
            }


        })
        .catch(handleNetworkError);
    };
};


export const fetchLiveStoryChildren = (id) => {
    return dispatch => {

        dispatch(requestLiveStoryChildren());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v2/pages/?child_of=${id}&type=frettabladid_cms.LiveStoryItemPage&fields=rich_body,id,title2,first_revision_created_at,is_key_event&order=-first_published_at&limit=1000`


        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveLiveStoryChildren(response));
            } catch (e) {
                dispatch(receiveLiveStoryChildrenError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

