import React from 'react'
import ReactDOM from 'react-dom'
import CurrencyTable from '../../components/currency-table'
import { store } from '../../store'

$.fn.CurrencyTable = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    options.namespace = "landsbanki";
    options.tags = ["landsbankikaupgengi,landsbankisolugengi"];
    ReactDOM.render(React.createElement(CurrencyTable, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-currencytable]').CurrencyTable();
});
