import React from "react";
import { connect } from 'react-redux'
import { fetchStockTimeSeries } from './actions'
import HighLowGraph from '../../components/high-low-graph'
import moment from 'moment'
import { renderPercentage, renderFloat, formatISK, renderNumber } from '../../utils'

import StockChart from '../../components/stock-chart'

class Stock extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount(prevProps, prevState) {

    const { namespace } = this.props
    let params = {
      short_code: this.props.code,
      full_history: true,
    }
    this.props.dispatch(fetchStockTimeSeries(namespace, params))
  }

  componentDidUpdate(prevProps, prevState) {
    const { namespace } = this.props
    if (this.props.code != prevProps.code) {
      let params = {
        short_code: this.props.code,
        full_history: true,
      }
      this.props.dispatch(fetchStockTimeSeries(namespace, params))
    }

  }


  render() {


    if (!this.props.stock.isFetching && Object.keys(this.props.stock.data).length > 0) {

      const { historical_data, financial_data, calendar_data, owner_data, fundamentals_data, name, short_code } = this.props.stock.data

      var strToQtr = (val) => {
        let parts = val.split("-")
        let quarter = ""
        if (parts.length == 1) {
          parts.push("Q5")
        }
        return {
          quarter: parseInt(parts[1].replace('Q', '')),
          year: parseInt(parts[0]),
        }
      }

      let _fundamentals_data = fundamentals_data.sort((a, b) => {
        var a = strToQtr(a.period)
        var b = strToQtr(b.period)

        return a.year === b.year ? a.quarter - b.quarter : a.year - b.year

      }).reverse().slice(0, 4)

      // let keys = [
      //   "__Rekstrarreikningur",
      //   "Rekstrartekjur:sales",
      //   "EBIDTA:ebitda",
      //   "Hagnaður:np",
      //   "Hagnaður á hlut:eps:2",
      //   "__Efnahagsreikningur",
      //   "Eignir:totalassets",
      //   "Eigið fé:shequity",
      //   "Eigið fé og skuldir:totalassets",
      //   "__Sjóðstreymi",
      //   "Veltufé frá rekstri:cashflowbwc",
      //   "Handbært frá rekstri:cashflowawc",
      //   "Fjárfestingarhreyfingar:cashflowia",
      //   "Fjármögnunarhreyfingar:cashflowfa",
      //   "Breyting á handbæru fé:cashflowtotal",
      //   "Handbært fé í lok tímabils:cce",
      // ]

      // let key_stats_columns = [
      //   { title: "", dataIndex: "key" },
      //   { title: "", dataIndex: "value", align: "right" },
      // ]

      // let key_stats_data = []

      // let table_columns = [
      //   { title: "", dataIndex: "title" },
      // ]

      // for (var f of _fundamentals_data) {
      //   table_columns.push({ title: <strong>{f.period}</strong>, dataIndex: f.period, align: "right" })
      // }

      // let table_data = []

      // let i = 0
      // for (var k of keys) {
      //   if (k.startsWith("__")) {
      //     table_data.push({ key: i, title: <strong>{k.replace("__", "")}</strong>, dataIndex: "title" })
      //     i++
      //     continue
      //   }
      //   else {
      //     let title = k.split(":")[0]
      //     let key_name = k.split(":")[1]
      //     let decimals = k.split(":")[2]

      //     let obj = {
      //       key: i,
      //       title: title,
      //     }
      //     for (var f of _fundamentals_data) {
      //       obj[f.period] = (decimals === undefined ? formatISK(f[key_name]) : formatISK(f[key_name], parseInt(decimals)))
      //     }
      //     table_data.push(obj)
      //     i++
      //   }
      // }

      // const table_data = [
      //   {key: 1, title: <strong>Rekstrarreikningur</strong>, colSpan: 0},
      //   {key: 2, title: "Rekstrartekjur", 2016: formatISK(4371698262), 2017: formatISK(4204424926)},
      // ]

      let calendar_table_columns = [
        { title: "", dataIndex: "date" },
        { title: "", dataIndex: "title" },
      ]

      let calendar_table_data = calendar_data.reverse().map(item => {
        let obj = {
          date: moment(item.timestamp).format("D. MMM YYYY")
        }

        let text = null

        if (item.source) {
          text = <a href={item.source} target="_blank">{item.title} <i className="fa fa-external-link" aria-hidden="true"></i></a>
        } else {
          text = <span>{item.title}</span>
        }
         
        let attachments = item.attachments.map((a) => {
            return <a href={a} target="_blank" className="xs-pl-5 xs-pr-5"><i className="fa fa-file"></i></a>
        })

        obj.title = <span>{text} {attachments}</span>

        return obj
      })



      const data = owner_data.map((d) => {
        return {
          company: d.owner,
          stock: d.capitalprc,
        }
      })

      // const ds = new DataSet();
      // const dv = ds.createView().source(data);

      // dv.transform({
      //   type: 'sort',
      //   callback(a, b) {
      //     return a.stock - b.stock;
      //   }
      // });

      return (<div class="container stock-page-content">
          <div class="row">
            <div class="col-12">
              <h2>{name} <span>({short_code})</span></h2>
              {financial_data.description &&
                <p>{financial_data.description}</p>
              }
            </div>
          </div>
          <div class="row">
              <div class="col-md-3 col-sm-4 col-12">
                <dl>
                  <dt>Auðkenni</dt>
                  <dd className="text-right">{short_code}</dd>
                  <br></br>
                  <dt>ISIN</dt>
                  <dd className="text-right">{financial_data.isin}</dd>
                  <br></br>
                  <dt>Gjaldmiðill</dt>
                  <dd className="text-right">{financial_data.tradecurrency}</dd>
                </dl>
              </div>
              <div class="col-md-2 col-sm-3 col-12">
                <dl>
                    <dt>Verð</dt>
                    <dd className="text-right">{renderNumber(financial_data.lastprice)}</dd>
                    <br></br>
                    <dt>Kaup</dt>
                    <dd className="text-right">{renderNumber(financial_data.bidprice)}</dd>
                    <br></br>
                    <dt>Sala</dt>
                    <dd className="text-right">{renderNumber(financial_data.askprice)}</dd>
                </dl>
              </div>
              <div class="col-md-3 col-sm-5 col-12">
                <dl className="w-48">
                  <dt>Dagsbreyting</dt>
                  <dd className="text-right">{renderPercentage(financial_data.diff1dprc)}</dd>
                  <br></br>
                  <dt>1 mánuður</dt>
                  <dd className="text-right">{renderPercentage(financial_data.diff1mprc)}</dd>
                  <br></br>
                  <dt>1 ár</dt>
                  <dd className="text-right">{renderPercentage(financial_data.diff1yprc)}</dd>
                </dl>
              </div>
              { financial_data.lastprice &&
              <div class="col-md-4 col-12">
                Innan árs
                <HighLowGraph max={financial_data.highprice1y} min={financial_data.lowprice1y} current={financial_data.lastprice} />
              </div>
              }
          </div>
          <div class="row">
            <div class="col-12 chart-col">
              <h3>Söguleg gögn</h3>
              <div className="chart-container">
                {historical_data.length > 0 &&
                  <StockChart data={historical_data} title={name} short_code={this.props.code} showSecondGraph={false} />
                }
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return <div />
    }
  }
}

Stock.defaultProps = {
  showAds: true
}



function mapStateToProps(state) {

  var f = {
    stock: {},
  }
  f.stock = (state.stock === undefined) ? {} : state.stock
  return f;
}

export default connect(mapStateToProps)(Stock);
