import React from 'react';

import { types } from './actions'
import moment from 'moment'

import { Link } from 'react-router-dom'


export function stock(state = {
    isFetching: false,
    data: {},
}, action) {

    switch (action.type) {
        case types.REQUEST:
            state.isFetching = true
            return state
        case types.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state
        default:
            return state
    }

}