import React from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import moment from 'moment'


let chart;
class StockChart extends React.Component {


    constructor(props) {
        super(props)
    }

    getData() {
        // return this.props.data.map((i) => {
        //     return [
        //         moment(i.date).valueOf(),
        //         i.value
        //     ]
        // }).sort((a, b) =>  a[0] < b[0] ? -1 : 1 )
        if (Array.isArray(this.props.data) && this.props.data.length > 0) {
            let result = this.props.data.map((i) => {
                return [
                    moment(i.date).valueOf(),
                    i.value
                ]
            }).sort((a, b) =>  a[0] < b[0] ? -1 : 1 )
            return [{
                name: this.props.title,
                data: result,
                type: 'area',
                threshold: null,
                tooltip: {
                    valueDecimals: 2
                }
            }]
        }


        return this.props.series
    }


    render() {

        if (this.props.data.length > 0 || this.props.series) {
        
            let options = {

                chart: {
                    height: 400
                },
        
                title: {
                    text: this.props.title
                },
        
                // subtitle: {
                //     text: 'Click small/large buttons or change window size to test responsiveness'
                // },
        
                rangeSelector: {
                    selected: 1,
                    inputEnabled: false,

                    buttons: [{
                        type: 'month',
                        count: 1,
                        text: '1m'
                    }, {
                        type: 'month',
                        count: 3,
                        text: '3m'
                    }, {
                        type: 'month',
                        count: 6,
                        text: '6m'
                    }, {
                        type: 'ytd',
                        text: 'YTD'
                    }, {
                        type: 'year',
                        count: 1,
                        text: '1y'
                    }, {
                        type: 'all',
                        text: 'Allt'
                    }],
                },
                colors: ["#F9B000"],
        
                series: this.getData(),
                credits: {
                    text: "Markadurinn.is",
                    href: "https://www.markadurinn.is"
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            chart: {
                                height: 300,
                            },
                            subtitle: {
                                text: null
                            },
                            navigator: {
                                enabled: false
                            },
                        },
                    }]
                }
            }

            return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    constructorType={'stockChart'}
                />
            )



        } else {
            return (<div />)
        }
    }
}

StockChart.defaultProps = {
    data: [],
    fields:['value'],
    title: '',
    showSecondGraph: true
}

export default StockChart;
