import React from 'react';

import {types} from './actions'
import moment from 'moment'
import CurrencyInput from './currencyInput'
import { renderPercentage } from '../../utils'
import { store } from '../../store'


export function currencyTable(state = {
    isFetching: false,
    currencies: {
        data: [],
        columns: [],
    }
}, action) {

    switch(action.type) {
        case types.REQUEST:
            state.isFetching = true
            return state
        case types.RECEIVE:
            const columns = [
              {
                title: "",
                dataIndex: "currency",
                key: "currency",
                render: text => <span><img src={`https://assets.l.is/img/icons/flags/${text}.png`} /> <a href={`/currency/${text}`}>{text}</a></span>,
              },
              {
                title: "Breyting",
                dataIndex: "change",
                key: "change",
                "className": 'column-money'
              }
            ]

            if(action.query.showActiveCurrencies){
                columns.unshift({
                    title: 'Sýna í grafi',
                    dataIndex: 'checkbox',
                    key: 'checkbox'
                })
            }
    
            let data = []
    
            let nestedData = {}
    
            for (var item of action.data.results) {
                if (item.tags.length > 0) {
                    for (var tag of item.matching_tags) {
                        if (columns.filter(d => d.key === tag.slug).length == 0) {
                            columns.push({
                                title: tag.name.toLowerCase().includes("kaupgengi") ? "Kaup" : "Sala" ,
                                dataIndex: tag.slug,
                                key: tag.slug,
                                "className": 'column-money'
                            })
                        }
    
                        if (Object.keys(nestedData).includes(item.currency.short_code)) {
                            nestedData[item.currency.short_code][tag.slug] = item.value
                            nestedData[item.currency.short_code]["date"] = moment(item.timestamp).format("D/M/YYYY")
                            nestedData[item.currency.short_code]["currency"] = item.currency.short_code
                            nestedData[item.currency.short_code]["key"] = item.currency.short_code
                            nestedData[item.currency.short_code]["change"] = renderPercentage(item.change*100)
                        } else {
                            let obj = {
                                "date": moment(item.timestamp).format("D/M/YYYY"),
                                "currency": item.currency.short_code,
                                "key": item.currency.short_code,
                                "change": renderPercentage(item.change*100),
                            }
                            obj[tag.slug] = item.value
                            nestedData[item.currency.short_code] = obj
                        }
                        if(action.query.showActiveCurrencies){
                            var checked = item.currency.short_code === action.query.showActiveCurrencies
                            nestedData[item.currency.short_code]["checked"] = checked
                        }
                    }
                }
            }
            if (action.key === 'landsbanki') {
                columns.push({
                    title: "Myntbreyta",
                    dataIndex: "convert",
                    key: "convert",
                    "className": 'column-money'
                })
                data.push({
                    change: "-",
                    currency: "ISK",
                    key: "ISK",
                    landsbankikaupgengi: "1",
                    landsbankisolugengi: "1",
                    convert: <CurrencyInput currencyCode={"ISK"} />
                })
            }

            for(let key of Object.keys(nestedData)) {
                data.push(nestedData[key])
            }

            state = {...state}

            state[action.key] = {}
            state[action.key].isFetching = false
            state[action.key].data = data
            state[action.key].columns = columns

            return state
        
        case types.CHECKED:
            
            state = {...state}
            if(state[action.key]){
                state[action.key].data = state[action.key].data.map((el) => {
                    if(el.key === action.currencyKey){
                        el.checked = action.checked
                    }
                    return el
                })
            }
            return state

        default:
            return state
    }

}

export function currencyInputs(state = {}, action) {

    switch(action.type) {
        case types.RECEIVE:
            state = {...state}
            for (var item of action.data.results) {
                for (var tag of item.matching_tags) {
                    if(!state[item.currency.short_code]){
                        state[item.currency.short_code] = {
                            "code": item.currency.short_code,
                            "value": 0.00
                        }
                    }
                    if(tag.name.toLowerCase().includes("kaupgengi")){
                        state[item.currency.short_code].kaup = +item.value
                    }else{
                        state[item.currency.short_code].sala = +item.value
                    }
                }
            }
            state["ISK"] = {
                code: "ISK",
                kaup: 1,
                sala: 1,
                value: 0,
            }
            return state
        case types.UPDATE_VALUES:
            state = {...state}
            for(var n in state){
                if(n === action.short_code){
                    state[n].value = +action.value
                }else{
                    state[n].value = (state[action.short_code].kaup * (+action.value)) / state[n].kaup
                }
                state[n].value = Math.round(state[n].value * 100) / 100
            }
            return state

        default:
            return state
    }
}