import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const dagskraActionTypes = createTypes([
  'REQUEST',
  'RECEIVE',
  'ERROR',
  'SUCCESS',
  'SET_DATE'
], 'DAGSKRA');


export const requestDagskra = (key) => {
  return {
    type: dagskraActionTypes.REQUEST,
    key: key,
  };
};

export const receiveDagskra = (response, key) => {
  return {
    type: dagskraActionTypes.RECEIVE,
    data: response,
    key: key,
  };
};

export const receiveDagskraError = () => {
  return {
    type: dagskraActionTypes.ERROR,
  };
};

export const setDagskraDate = (date, key='') => {
  return {
    type: dagskraActionTypes.SET_DATE,
    date: date,
    key: key,
  };
}

export const fetchDagskra = (data, key='') => {
  return dispatch => {
    dispatch(requestDagskra(key));
    const { starts, ends } = data;
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    let url = `/api/v1/dagskra/?starts=${starts}&ends=${ends}`

    return fetch(url, {
      headers: headers
    })
    .then(parseJSON)
    .then(response => {
      try {
        dispatch(receiveDagskra(response, key));
      } catch (e) {
        dispatch(receiveDagskraError(response))
      }
    })
    .catch(handleNetworkError);
  };
};
