
import {types} from './actions'

export function stockTicker(state = {
    isFetching: false,
    stocks: {
        data: [],
    }
}, action) {

    switch(action.type) {
        case types.REQUEST:
            state.isFetching = true
            return state
        case types.RECEIVE:
            let data = []
            
            for (var item of action.data.instruments) {
                data.push({
                    stock: item.short_code, 
                    lastprice: item.financial_data.lastprice, 
                    change: item.financial_data.diff1mprc, 
                    bidprice: item.financial_data.bidprice,
                    historical_data: item.historical_data,
                })
            }

            state = {...state}

            state[action.key] = {}
            state[action.key].isFetching = false
            state[action.key].data = data

            return state
        default:
            return state
    }

}