import React from 'react';

import {types} from './actions'
import moment from 'moment'

import { renderPercentage, renderFinancialShort, groupBy } from '../../utils'

import { Link } from 'react-router-dom'


export function stockTable(state = {
    isFetching: false,
    stocks: {
        data: [],
        columns: [],
    }
}, action) {

    switch(action.type) {
        case types.REQUEST:
            state.isFetching = true
            return state
        case types.RECEIVE:
            const columns = [
                {
                    title: "Auðkenni",
                    dataIndex: "key",
                    key: "key",

                    render: (text, row, index) => {
                        return <Link to={`${action.path}/${text}`}>{text}</Link>
                    }


                },
                {
                    title: "Verð",
                    dataIndex: "lastprice",
                    key: "lastprice",
                    "className": 'column-money',
                    render: (text, row, index) => {
                        return text == null ? '-' : text.toFixed(2)
                    }
                },
                {
                    title: "Breyting",
                    dataIndex: "change",
                    key: "change",
                    "className": 'column-money',
                    render: (text, row, index) => {
                        return renderPercentage(text)
                    }
                },
                {
                    title: "Kaup",
                    dataIndex: "bidprice",
                    key: "bidprice",
                    "className": 'column-money',
                    render: (text, row, index) => {
                        return text == null ? '-' : text.toFixed(2)
                    }
                },
                {
                    title: "Sala",
                    dataIndex: "askprice",
                    key: "askprice",
                    "className": 'column-money',
                    render: (text, row, index) => {
                        return text == null ? '-' : text.toFixed(2)
                    }

                },
                {
                    title: "Magn",
                    dataIndex: "turnover",
                    key: "turnover",
                    "className": 'column-money',
                    render: (text, row, index) => {
                        return renderFinancialShort(text)
                    }
                }
            ]

            state = {...state}
            state[action.key] = {}


            var ORDERING = ["Óverðtryggt", "Verðtryggt", "Annað"];

            let data = []
            
            let grouped_data = groupBy(action.data.instruments, item => item.subgroup ? item.subgroup.name : "Annað")
            grouped_data = new Map([...grouped_data.entries()].sort(function(a,b) { return ORDERING.indexOf(a[0]) - ORDERING.indexOf(b[0]); }))

            let grouped_data_keys = Array.from(grouped_data.keys())

            if (grouped_data_keys.length === 1 && grouped_data_keys[0] === "Annað") {
                let instruments = action.data.instruments.sort((a, b) => {
                    return a.short_code < b.short_code ? -1 : 1
                })
        
                for (var item of instruments) {
                    data.push({
                        key: item.short_code, 
                        lastprice: item.financial_data.lastprice, 
                        change: item.financial_data.diff1dprc, 
                        bidprice: item.financial_data.bidprice, 
                        askprice: item.financial_data.askprice, 
                        turnover: item.financial_data.turnover, 
                    })
                }
                state[action.key].grouping = false
            } else {
                        
                for (var [group, items] of grouped_data) {
                    let g = {key: group, lastprice: "", bidprice: "", askprice: "", turnover: "", change: "", date: "", children: []};
                    for (var item of items) {
                        g.children.push({
                            key: item.short_code,
                            change: item.financial_data.diff1dprc,
                            lastprice: item.financial_data.lastprice,
                            bidprice: item.financial_data.bidprice,
                            askprice: item.financial_data.askprice,
                            turnover: item.financial_data.turnover,
                            date: moment(item.timestamp).format("D/M/YYYY")
                        })
                    }
                    data.push(g);
                }

                state[action.key].grouping = true
            }
  

            
            state[action.key].isFetching = false
            state[action.key].data = data
            state[action.key].columns = columns

            return state
        default:
            return state
    }

}