import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import queryString from 'query-string'

export const types = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'Stocks');


export const requestStock = () => {
    return {
        type: types.REQUEST,
    };
};

export const receiveStock = (response, key, path) => {
    return {
        type: types.RECEIVE,
        path: path,
        data: response,
        key: key,
    };
};

export const receiveStockError = () => {
    return {
        type: types.ERROR,
    };
};

export const fetchStock = (key, slug, path) => {
    return dispatch => {

        dispatch(requestStock());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch(`https://www.markadurinn.is/api/v1/instrument_group/${slug}/`, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveStock(response, key, path));
            } catch (e) {
                dispatch(receiveStockError(response))
            }


        })
        .catch(handleNetworkError);
    };
};